<template>
  <div id="komform-container" />
</template>

<script>
export default {
  mounted() {
    // Load external script
    const script = document.createElement('script')
    script.src = `${process.env.VUE_APP_BASE_URL_KOMFORM}/partner/embed.js`
    script.async = true

    // Append the script to the document
    document.head.appendChild(script)
  },
}
</script>
